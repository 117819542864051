import styled from "styled-components";

const StyledContainer = styled.div`
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default function Container(props) {
  return <StyledContainer {...props} />;
}
