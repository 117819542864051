import React from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "antd";

import Icon from "../Icon";

export default function UpgradeButton({ to, canChangePlan, ...props }) {
  const button = (
    <Button
      type="primary"
      block
      icon={<Icon type="upgrade" className="m-r:.8 f:2" />}
      size="large"
      children={"Upgrade do plano"}
      style={{
        lineHeight: 1,
        paddingTop: "8px!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      disabled={!canChangePlan}
      {...props}
    />
  );
  if (!canChangePlan)
    return <Tooltip title="Você ainda tem faturas em aberto">{button}</Tooltip>;
  if (to) return <Link to={to}>{button}</Link>;
  return button;
}
