import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import cardInfo from "./pages/cardInfo";
import checkout from "./pages/checkout";
import invoiceInfo from "./pages/invoiceInfo";
import subscription from "./pages/subscription";
import changePlan from "./pages/changePlan";
import changeQuota from "./pages/changeQuota";
import ErrorBoundary from "./components/ErrorBoundary";

export default function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Switch>
          <Route
            path="/assinatura/alterar-plano/:planId"
            component={changePlan}
          />
          <Route path="/assinatura/alterar-cota" component={changeQuota} />
          <Route path="/atualizar-dados/cartao" component={cardInfo} />
          <Route path="/atualizar-dados/cobranca" component={invoiceInfo} />
          <Route path="/atualizar-dados" component={checkout} />
          <Route path="/assinatura/:tab?" component={subscription} />
          <Route
            path="/assinatura/trocar-plano/:planId"
            component={subscription}
          />
          <Redirect from="/" to="/assinatura" exact />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
