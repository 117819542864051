import React from "react";
import { Avatar, Button, Card } from "antd";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../utils/locale";
import Icon from "../Icon";
import PlanTag from "./PlanTag";
import UpgradeButton from "./UpgradeButton";

export default function PlanCard({
  plan,
  isActive,
  className,
  onSelect,
  canChangePlan,
  ...props
}) {
  const {
    name,
    total_price,
    description,
    has_quota_expansion,
    quotas,
    auto_payment,
    button_label,
    button_link,
  } = plan;

  return (
    <Card
      className={classnames(className, "d:f flx-d:c")}
      title={
        <div className={isActive ? "c:white" : ""}>
          <PlanTag>{name}</PlanTag>
          {auto_payment ? (
            has_quota_expansion && quotas[0]?.num_items ? (
              <div className="d:f">
                <span className="f:6 f-w:700">
                  {currencyFormatter(quotas[0].price_per_item || 0)}
                </span>
                <span className="m-t:.8 m-l:.8">/ posição</span>
              </div>
            ) : (
              <span className="d:b f:6 f-w:700">
                {currencyFormatter(total_price || 0)}
              </span>
            )
          ) : (
            <span className="d:b f:3 f-w:700 m-t:.8">Preço sob consulta</span>
          )}
        </div>
      }
      headStyle={{
        background: isActive ? "var(--blue)" : "var(--bright-blue)",
        position: "relative",
        minHeight: 120,
      }}
      bodyStyle={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
    >
      <PlanDescription className="m-b:1" description={description} />

      <div className="m-t:a">
        {isActive ? (
          has_quota_expansion &&
          canChangePlan && (
            <Link to="/assinatura/alterar-cota">
              <Button
                className="d:f a-i:c j-c:c"
                type="secondary"
                size="large"
                block
                icon={<Icon type="plus" className="m-r:.8 f:2" />}
              >
                Adicionar posições
              </Button>
            </Link>
          )
        ) : (
          <UpgradeButton
            canChangePlan={canChangePlan}
            onClick={auto_payment ? onSelect : undefined}
            children={
              auto_payment
                ? "Escolher plano"
                : button_label || "Fale com nossos consultores"
            }
            href={auto_payment ? undefined : button_link}
            target="_blank"
            {...(auto_payment ? {} : { icon: undefined })}
          />
        )}
      </div>
    </Card>
  );
}

export function PlanDescription({ description, ...props }) {
  let formattedDescription;
  try {
    if (description) formattedDescription = JSON.parse(description);
  } catch (e) {}

  return (
    <div {...props}>
      {formattedDescription ? (
        <ul className="l-s-t:n p-l:0">
          {formattedDescription.map((i) => (
            <li className="d:f a-i:c m-t:.8" key={i}>
              <div className="d:b  m-r:.8">
                <Avatar className="bg:black" size="small">
                  <Icon type="check" />
                </Avatar>
              </div>
              <span>{i}</span>
            </li>
          ))}
        </ul>
      ) : (
        description
      )}
    </div>
  );
}
