import React, { useEffect } from "react";
import { Button, Card, Form } from "antd";

import PrimaryLayout from "../components/PrimaryLayout";
import AddressForm from "../components/AddressForm";
import PayerInfoForm from "../components/PayerInfoForm";
import withOriginBoundary from "../components/withOriginBoundary";
import useInitialDataFromMessage from "../hooks/useInitialDataFromMessage";

function InvoiceInfo() {
  const [form] = Form.useForm();
  const initialData = useInitialDataFromMessage();

  useEffect(() => {
    if (initialData) form.resetFields();
  }, [initialData, form]);

  const handleSubmit = (data) => {
    window.opener.postMessage({ type: "PAYMENT_MANAGER", data }, "*");
  };

  return (
    <PrimaryLayout>
      <Card>
        <Form
          form={form}
          initialValues={initialData}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <PayerInfoForm form={form} />
          <AddressForm form={form} hideBeforeCep={false} />
          <div className="t-a:r">
            <Button type="primary" size="large" htmlType="submit">
              Salvar alterações
            </Button>
          </div>
        </Form>
      </Card>
    </PrimaryLayout>
  );
}

export default withOriginBoundary(InvoiceInfo);
