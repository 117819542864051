import React, { useState } from "react";
import { Button, Card, Form, Spin } from "antd";

import PrimaryLayout from "../components/PrimaryLayout";
import withOriginBoundary from "../components/withOriginBoundary";
import CardForm from "../components/CardForm";
import useJunoCardManager from "../hooks/useJunoCardManager";
import { mapCardData } from "./checkout";

function CardInfo() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {
    checkout,
    cardValidation,
    validateAndGenerateHash,
  } = useJunoCardManager();

  const handleSubmit = async (data) => {
    if (loading) return;
    setLoading(true);
    const cardData = mapCardData(data)[1];
    try {
      const credit_card_hash = await validateAndGenerateHash(cardData);
      window.opener.postMessage(
        { type: "PAYMENT_MANAGER", data: credit_card_hash },
        "*"
      );
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <PrimaryLayout>
      {checkout ? (
        <Card>
          <CardForm
            form={form}
            validators={cardValidation}
            onFinish={handleSubmit}
          />
          <Button type="primary" onClick={form.submit} loading={loading}>
            Salvar
          </Button>
        </Card>
      ) : (
        <div className="p-y:6 t-a:c">
          <Spin />
        </div>
      )}
    </PrimaryLayout>
  );
}

export default withOriginBoundary(CardInfo);
