import React from "react";
import { Col, Row } from "antd";
import { useHistory } from "react-router";

import PlanCard from "./PlanCard";

export default function Plans({ plans = [], canChangePlan, activePlan }) {
  const { push } = useHistory();
  const onChangePlan = async (plan) => {
    try {
      push({ pathname: `/assinatura/alterar-plano/${plan.id}` });
      // showModal(true);
      // await api.post("/plans/billing_info/", data);
      // await api.post(`/plans/change_plan/${plan.id}/`);
      // showModal(false);
      // openModal({
      //   children: (
      //     <Result status="success" title={t("PlansModal.planChange.success")} />
      //   ),
      // });
      // mutate("/plans/plan_info/");
    } catch (e) {
      // showModal(false);
      // openModal({
      //   children: (
      //     <Result
      //       status="error"
      //       title={t("PlansModal.error")}
      //       subTitle={e?.response?.status === 400 ? e.response.data : undefined}
      //     />
      //   ),
      // });
    }
  };

  return (
    <div>
      <Row gutter={20} justify="center">
        {plans.map((plan) => (
          <Col key={plan.id} span={6}>
            <PlanCard
              plan={plan}
              isActive={activePlan === plan.id}
              className="h:full"
              onSelect={() => onChangePlan(plan)}
              canChangePlan={canChangePlan}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
