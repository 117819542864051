import React from "react";
import { Tabs } from "antd";
import useSWR from "swr";
import { useHistory, useRouteMatch } from "react-router-dom";

import { get } from "../../api/api";
import Overview from "./Overview";
import Plans from "./Plans";
import PaymentHistory from "./PaymentHistory";
import BillingAddress from "./BillingAddress";
import PaymentInfo from "./PaymentInfo";
import PrimaryLayout from "../PrimaryLayout";

export default function Subscription() {
  const activeTab = useRouteMatch().params.tab || "resumo";
  const { push } = useHistory();
  const { data: planInfo } = useSWR("/plans/plan_info/", get);
  const { data: billingInfo, mutate: mutateBillingInfo } = useSWR(
    "/plans/billing_info/",
    get,
    {
      revalidateOnFocus: false,
    }
  );
  const { data: plans } = useSWR("/plans/plans/", get, {
    revalidateOnFocus: false,
  });
  const { data: paymentRecords } = useSWR("/plans/payment_records/", get);

  const canChangePlan = planInfo?.can_change_plan;

  const tabs = [
    [
      "Resumo",
      Overview,
      {
        activePlan: planInfo?.plan,
        history: paymentRecords?.results,
        userQuota: planInfo?.quotas?.[0],
        monthlyPrice: planInfo?.monthly_price,
        credits: planInfo?.credits,
        canChangePlan,
      },
      "resumo",
    ],
    [
      "Planos",
      Plans,
      { plans: plans, activePlan: planInfo?.plan.id, canChangePlan },
      "planos",
    ],
    [
      "Histórico",
      PaymentHistory,
      { data: paymentRecords?.results },
      "historico",
    ],
    billingInfo?.last_four_card_number && [
      "Dados de pagamento",
      PaymentInfo,
      { billingInfo },
      "dados-de-pagamento",
    ],
    Object.keys(billingInfo || {}).length > 0 && [
      "Dados de cobrança",
      BillingAddress,
      { initialValues: billingInfo, mutate: mutateBillingInfo },
      "endereco-cobranca",
    ],
  ].filter(Boolean);

  return (
    <PrimaryLayout title="Assinatura">
      <Tabs activeKey={activeTab} onChange={(k) => push(`/assinatura/${k}`)}>
        {tabs.map(([tabTitle, Component, props, key], i) => (
          <Tabs.TabPane key={key} tab={tabTitle}>
            <Component {...props} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </PrimaryLayout>
  );
}
