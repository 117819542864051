import React from "react";
import { Badge, Button, Card, Col, List, message, Row, Space } from "antd";
import moment from "moment";
import { mutate } from "swr";

import { currencyFormatter } from "../../utils/locale";
import PlanTag from "./PlanTag";
import { Description } from "./Overview";
import api from "../../api/api";

export default function PaymentHistory({ data = [] }) {
  const onRetryPayment = async (id) => {
    try {
      await api.post(`/plans/pay_charge/${id}/`);
      message.success("Pagamento realizado com sucesso!");
      mutate("/plans/payment_records/");
    } catch (e) {
      if (e.response) {
        if (e.response.status === 404) {
          message.error("Pagamento não encontrado!");
        } else if (e.response.status === 400) {
          message.error(e.response.data.error);
        }
      }
    }
  };

  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Card className="w:full">
            <Row align="middle">
              <Col span={3}>
                <Badge
                  status={
                    {
                      Pago: "success",
                      "Não pago": "warning",
                      Cancelado: "red",
                    }[item.status]
                  }
                />
                <span className="f-w:500">{item.status}</span>
              </Col>
              <Col span={6}>
                <PlanTag>{item.plan}</PlanTag>
                {item.amount_quota_expansion && (
                  <span className="m-l:.5 f-w:500 c:black.5">
                    + {item.amount_quota_expansion} posições
                  </span>
                )}
              </Col>
              <Col span={6}>
                <Description
                  label="Data da fatura"
                  value={moment(item.created_date).format("DD/MM/YYYY")}
                />
              </Col>
              <Col span={3}>
                <Description
                  label="Valor"
                  value={currencyFormatter(item.amount)}
                />
              </Col>
              <Col span={6} className="t-a:r">
                <Space>
                  {item.status === "Não pago" && item.checkout_link && (
                    <Button href={item.checkout_link} target="_blank">
                      Visualizar fatura
                    </Button>
                  )}
                  {item.status === "Não pago" && item.charge_type === "AUTO" && (
                    <Button
                      onClick={() => onRetryPayment(item.id)}
                      type="primary"
                    >
                      Retentar pagamento
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
          </Card>
        </List.Item>
      )}
    />
  );
}
