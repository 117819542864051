import { useState } from "react";
import { Form, Card as ACard, Col, Row, Button, Radio, message } from "antd";
import classnames from "classnames";

import AddressForm from "../components/AddressForm";
import PayerInfoForm from "../components/PayerInfoForm";
import PrimaryLayout from "../components/PrimaryLayout";
import CardForm from "../components/CardForm";
import Icon from "../components/Icon";
import { mapErrors } from "../utils/form";

export default function Checkout({
  loading,
  onSubmitPaymentInfo,
  cardValidation,
}) {
  const [step, setStep] = useState(0);
  const [form] = Form.useForm();

  const onContinue = async (data) => {
    try {
      onSubmitPaymentInfo(data);
    } catch (e) {
      const status = e?.response?.status;
      const data = e?.response?.data;
      if (typeof data === "string") {
        message.error(data);
      } else if (status === 400) {
        message.error("Campos incorretos, corrija os erros!");
        mapErrors(form, data);
      } else
        message.error(
          "Parece que houve um erro! Verifique se todas as informações inseridas estão corretas"
        );
    }
  };

  return (
    <PrimaryLayout title="Checkout">
      <Form
        form={form}
        preserve
        layout="vertical"
        onFinish={() => onContinue(form.getFieldValue())}
      >
        {step === 0 ? (
          <Row gutter={[30, 30]} justify="center">
            <Col span={24} md={12} lg={9}>
              <PaymentTypeSelect
                form={form}
                onSubmit={() => setStep(step + 1)}
              />
            </Col>
          </Row>
        ) : (
          <Row gutter={[30, 30]} justify="center">
            {form.getFieldValue("info_type") === "FULL" && (
              <Col span={24} md={12} lg={8}>
                <CardInfo form={form} validators={cardValidation} />
              </Col>
            )}
            <Col span={24} md={12} lg={8}>
              <BillingInfo
                form={form}
                partialData={form.getFieldValue("info_type") === "PARTIAL"}
              />
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                className="m-t:3"
                onClick={form.submit}
                loading={loading}
              >
                Salvar dados de pagamento
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </PrimaryLayout>
  );
}

function Card({ title, children, ...props }) {
  return (
    <ACard {...props}>
      <h2 className="c:primary m-b:2">{title}</h2>
      {children}
    </ACard>
  );
}

function CardInfo(props) {
  return (
    <Card title="Dados do cartão">
      <CardForm {...props} />
    </Card>
  );
}

function BillingInfo({ form, partialData = false }) {
  return (
    <Card title="Dados de cobrança">
      <PayerInfoForm form={form} />
      {!partialData && <AddressForm form={form} />}
    </Card>
  );
}

function PaymentTypeSelect({ form, onSubmit }) {
  return (
    <Card title="Opção de pagamento">
      <Form.Item name="info_type">
        <CardSelect
          options={[
            {
              value: "PARTIAL",
              icon: "boleto",
              label: "Boleto",
              description: "receba um boleto mensalmente",
            },
            {
              value: "FULL",
              icon: "creditCard",
              label: "Cartão",
              description: "renovável mensalmente",
            },
          ]}
        />
      </Form.Item>
      <Button onClick={onSubmit} size="large" type="primary" block>
        Continuar
      </Button>
    </Card>
  );
}

function CardSelect({ options, value: fieldValue, onChange }) {
  return (
    <div className="t-a:c">
      {options.map(({ value, label, description, icon }) => (
        <ACard
          className={classnames(
            "m-b:2 t-a:l cur:p",
            fieldValue === value && "ant-card-selected"
          )}
          size="small"
          key={value}
          style={{
            height: "unset",
            lineHeight: 1,
          }}
          onClick={() => onChange(value)}
        >
          <div className="d:f a-i:c">
            <Radio checked={fieldValue === value} on />
            <Icon type={icon} className="c:primary f:4 m-r:1" />
            <p className="c:primary f-w:500 m-b:0 m-r:.4">{label}</p>
            <span className="f:.9 f-w:500"> · {description}</span>
          </div>
        </ACard>
      ))}
    </div>
  );
}
