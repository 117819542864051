import React from "react";
import { Button, Card, Col, Progress, Row } from "antd";
import moment from "moment";
import "moment/locale/pt-br";

import { currencyFormatter } from "../../utils/locale";
import Icon from "../Icon";
import PlanTag from "./PlanTag";
import UpgradeButton from "./UpgradeButton";
import PaymentHistory from "./PaymentHistory";
import { Link } from "react-router-dom";

moment.locale("pt-br");

export default function Overview({
  activePlan = {},
  userQuota = {},
  monthlyPrice,
  credits,
  canChangePlan,
  history,
}) {
  const today = moment();
  const nextPayment = today
    .clone()
    .add(today.get("date") <= 0 ? 0 : 1, "month")
    .set("date", 5);

  return (
    <div>
      <Row gutter={20} className="m-b:1">
        <Col span={14}>
          <PlanInfoCard
            activePlan={activePlan}
            userQuota={userQuota}
            monthlyPrice={monthlyPrice}
            canChangePlan={canChangePlan}
            className="h:full"
          />
        </Col>
        <Col span={10}>
          <Card
            className="h:full"
            bodyStyle={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <h5 className="f:1 c:black.4">Próximo pagamento</h5>
            <h4 className="f:2">
              em {nextPayment.format("DD [de] MMMM [de] YYYY")}
            </h4>
            {!!credits && (
              <span className="d:b m-b:.8">
                Créditos para o próx. mês: <b>{currencyFormatter(credits)}</b>
              </span>
            )}
            <Link to="/assinatura/historico" className="m-t:a">
              <Button type="secondary" block size="large">
                Ver histórico
              </Button>
            </Link>
          </Card>
        </Col>
      </Row>
      <div>
        <h4>Histórico</h4>
        <PaymentHistory data={history} />
      </div>
    </div>
  );
}

function PlanInfoCard({
  activePlan,
  userQuota,
  monthlyPrice,
  canChangePlan,
  ...props
}) {
  let percent, success, total, used;
  if (activePlan.has_quota_expansion) {
    total = userQuota.num_items;
    used = total - userQuota.unused_quota;
    if (userQuota.unused_quota < 0) {
      percent = 100;
      success = { percent: 100, strokeColor: "red" };
    } else {
      // if(userQuota.additional_quota){
      //   percent = 100;
      //   success = { percent:  };
      // }
      // else
      percent = (used / total) * 100;
    }
  }
  return (
    <Card {...props}>
      <Row gutter={20}>
        <Col span={12}>
          <div className="m-b:3">
            <h2 className="m-b:0">
              <span className="m-r:.8">Plan</span>
              <PlanTag>{activePlan.name}</PlanTag>
            </h2>
            {activePlan.has_quota_expansion && (
              <span className="c:black.3">
                + adicional de {userQuota.additional_quota} posições
              </span>
            )}
          </div>
          {activePlan.has_quota_expansion && (
            <div>
              <div className="d:f a-i:c m-b:.8">
                <h4 className="f:1 m-b:0 m-r:.8">
                  {used} de {total} posições
                </h4>
                {canChangePlan && (
                  <Link to="/assinatura/alterar-cota">
                    <Button
                      type="transparent"
                      shape="circle"
                      size="small"
                      icon={<Icon type="plus" />}
                    />
                  </Link>
                )}
              </div>
              <Progress
                percent={percent.toFixed(1)}
                success={success}
                status="normal"
              />
            </div>
          )}
        </Col>
        <Col span={12}>
          <div className="d:f">
            <span className="f:6 f-w:700">
              {currencyFormatter(monthlyPrice || 0)}
            </span>
            <span className="m-t:.8 m-l:.8 c:black.4 f-w:500">/ ao mês</span>
          </div>
          <div className="m-b:1">
            <span className="f:.9">
              (Plano base: {currencyFormatter(activePlan.base_price || 0)}{" "}
              {activePlan.has_quota_expansion &&
                `+ ${currencyFormatter(
                  userQuota.price_per_item || 0
                )} × ${total} posições`}
              )
            </span>
          </div>
          <div>
            <UpgradeButton
              to="/assinatura/planos"
              canChangePlan={canChangePlan}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export function Description({ label, value }) {
  return (
    <span className="f-w:500 c:black.5">
      {label}: <span className="c:black">{value}</span>
    </span>
  );
}
