import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Spin,
  message,
  Input,
  Avatar,
  Divider,
  Space,
} from "antd";
import useSWR from "swr";

import api, { get } from "../api/api";
import PrimaryLayout from "../components/PrimaryLayout";
import PaymentResult from "../components/PaymentResult";
import Icon from "../components/Icon";
import styled from "styled-components";

export default function ChangePlan({ history, match, location }) {
  const { planId } = match.params;
  const [loading, setLoading] = useState(false);
  const { data: billingInfo, isValidating } = useSWR(
    "/plans/billing_info/",
    get
  );
  const { data: plan } = useSWR(`/plans/plans/${planId}/`, get);
  const [response, setResponse] = useState();

  const handleChangePlan = useCallback(
    async (user_quota_items) => {
      if (loading) return;
      setLoading(true);
      try {
        const { data: response } = await api.post(
          `/plans/change_plan/${planId}/`,
          { user_quota_items }
        );
        setResponse(response);
      } catch (e) {
        const data = e?.response?.data;
        const status = e?.response?.status;

        message.error(
          status !== 500 && typeof data === "string"
            ? data
            : "Parece que houve um erro!"
        );
      }
      setLoading(false);
    },
    [planId, loading]
  );

  useEffect(() => {
    if (!isValidating && billingInfo && Object.keys(billingInfo).length === 0) {
      history.push({
        pathname: "/atualizar-dados",
        state: {
          redirectTo: location.pathname,
        },
      });
    }
  }, [isValidating, billingInfo, history, location]);

  return (
    <PrimaryLayout title="Trocar plano">
      <Row justify="center">
        <Col span={24} xl={8}>
          {plan && billingInfo ? (
            response ? (
              <PaymentResult data={response} />
            ) : (
              <PurchaseInfo
                details={plan}
                onSubmit={handleChangePlan}
                loading={loading}
              />
            )
          ) : (
            <div className="t-a:c p-y:9">
              <Spin />
            </div>
          )}
        </Col>
      </Row>
    </PrimaryLayout>
  );
}

function PurchaseInfo({ loading, details = {}, onSubmit }) {
  const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format;

  const minQuota = details.has_quota_expansion && details.quotas[0].num_items;
  const [quotaAmt, setQuotaAmt] = useState(minQuota);

  const value =
    details.total_price +
    (quotaAmt
      ? (quotaAmt - details.quotas[0].num_items) *
        details.quotas[0].price_per_item
      : 0);

  return (
    <>
      <Card className="o:h">
        <h2 className="c:primary m-b:2">Resumo da transação</h2>
        <Card size="small">
          <div className="d:f a-i:c">
            <div>
              <Avatar
                shape="square"
                size={60}
                style={{ background: "var(--primary)", opacity: 0.6 }}
              >
                <Icon type="plan" className="f:4" />
              </Avatar>
            </div>
            <div className="m-l:1">
              <h3 className="f-w:600 m-b:0">Plano {details.name}</h3>
              {details.has_quota_expansion && (
                <div>
                  <span className="f-w:500">
                    {currencyFormatter(details.quotas[0].price_per_item)}
                  </span>
                  <small style={{ color: "#666" }}> /posição</small>
                </div>
              )}
            </div>
          </div>
        </Card>
        {details.has_quota_expansion && (
          <div className="m-t:3">
            <h4 className="d:i-b m-b:0" style={{ color: "#666" }}>
              Posições
            </h4>
            <Space className="d-i:b f-w:600 fl:r">
              <Button
                size="small"
                disabled={quotaAmt <= minQuota}
                children="-"
                onClick={() => setQuotaAmt(quotaAmt - 1)}
              />
              <InputNumber
                value={quotaAmt}
                size="small"
                style={{ width: 60 }}
                onChange={(e) => setQuotaAmt(parseInt(e.target.value))}
                type="number"
                min={minQuota}
              />
              <Button
                size="small"
                children="+"
                onClick={() => setQuotaAmt(quotaAmt + 1)}
              />
            </Space>
          </div>
        )}
        <Divider />
        <div>
          <h4 className="d:i-b" style={{ color: "#666" }}>
            Valor total
          </h4>
          <span className="m-l:1 f:2 c:black f-w:600 fl:r">
            {currencyFormatter(value)}
            <small style={{ color: "#666" }}> /mês</small>
          </span>
        </div>
      </Card>
      <Button
        type="primary"
        size="large"
        block
        className="m-t:3"
        onClick={() => onSubmit(quotaAmt && quotaAmt - minQuota)}
        loading={loading}
        disabled={quotaAmt < minQuota}
      >
        Confirmar troca
      </Button>
      {quotaAmt < minQuota && (
        <div className="m-t:1" style={{ color: "red" }}>
          <span>O número mínimo de posições é 10</span>
        </div>
      )}
    </>
  );
}

const InputNumber = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
