import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Container from "./Container";

const Logo = styled.img`
  height: 50px;
`;

export default function PrimaryLayout({ children, title }) {
  return (
    <div className="p-y:4">
      <Container>
        <div className="d:f m-b:1 a-i:c">
          <Link to="/assinatura/">
            <Logo src={process.env.REACT_APP_MAIN_LOGO} alt="" />
          </Link>
          <h1 className="m-l:a c:primary f-w:600">{title}</h1>
        </div>
        {children}
      </Container>
    </div>
  );
}
