import { useState, useEffect } from "react";

export default function useExternalScript(handler) {
  const [data, setData] = useState();

  useEffect(() => {
    const onScriptLoaded = (e) => setData(handler(e));

    const script = document.createElement("script");
    script.onload = onScriptLoaded;

    script.src = process.env.REACT_APP_JUNO_API_URL;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [handler]);

  return data;
}
