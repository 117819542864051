import React, { useState } from "react";
import { Button, Card, Form, message, Space } from "antd";
import CardForm from "../CardForm";
import useJunoCardManager from "../../hooks/useJunoCardManager";
import { mapCardData } from "../../pages/checkout";
import api from "../../api/api";
import Icon from "../Icon";

export default function PaymentInfo({ billingInfo }) {
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const { cardValidation, validateAndGenerateHash } = useJunoCardManager();

  const handleSubmit = async (data) => {
    if (loading) return;
    setLoading(true);
    const cardData = mapCardData(data)[1];
    try {
      const credit_card_hash = await validateAndGenerateHash(cardData);
      await api.post(`/plans/billing_info/`, {
        credit_card_hash,
      });
      message.success("Alterações salvas com sucesso!");
      setEditing(false);
    } catch (e) {
      message.error(
        "Houve um erro com sua solicitação, tente novamente mais tarde"
      );
    }
    setLoading(false);
  };
  return (
    <div>
      {editing ? (
        <div>
          <CardForm
            form={form}
            validators={cardValidation}
            onFinish={handleSubmit}
          />
          <div className="t-a:r">
            <Space>
              <Button onClick={() => setEditing(false)} size="large">
                Cancelar
              </Button>
              <Button type="primary" size="large" onClick={form.submit}>
                Salvar
              </Button>
            </Space>
          </div>
        </div>
      ) : (
        <BillingInfoCard card={billingInfo} onEdit={() => setEditing(true)} />
      )}
    </div>
  );
}

function BillingInfoCard({ card, onEdit }) {
  return (
    <Card>
      <div className="d:f j-c:s-b a-i:c">
        <div className="d:f a-i:c">
          <Icon type="creditCard" className="m-r:.8 f:3" />
          <span>
            •••• •••• •••• <b>{card.last_four_card_number}</b>
          </span>
        </div>
        <span>
          Validade:{" "}
          <b>
            {card.expiration_month} / {card.expiration_year}
          </b>
        </span>
        <Button onClick={onEdit}>Editar</Button>
      </div>
    </Card>
  );
}
