import { MaskedInput } from "antd-masked-input";
import Axios from "axios";

export default function CepInput({ form, onChange, ...props }) {
  const handleChange = (e) => onChange(e.target.value.replace(/\D/g, ""));
  const handleBlur = () =>
    props.value?.length === 8 &&
    Axios.get(`https://viacep.com.br/ws/${props.value}/json/`).then(
      ({ data }) =>
        form.setFieldsValue({
          street: data.logradouro,
          neighborhood: data.bairro,
          avenue: data.bairro,
          city: data.localidade,
          state: data.uf,
        })
    );

  return (
    <MaskedInput
      mask="11111-111"
      onBlur={handleBlur}
      onChange={handleChange}
      {...props}
    />
  );
}
