import React from "react";
import { Result } from "antd";

export default function withOriginBoundary(Page) {
  return (props) => {
    if (
      !window.opener ||
      window.opener.origin.indexOf(process.env.REACT_APP_OPENER_DOMAIN) < 0
    )
      return (
        <main>
          <Result
            status="500"
            title="500"
            subTitle="Desculpe-nos, algo deu errado"
          />
        </main>
      );
    else return <Page {...props} />;
  };
}
