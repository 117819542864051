import { useState } from "react";
import { Form } from "antd";
import PaymentCard from "react-payment-card-component";

import CardFields from "./CardFields";

export default function CardForm({ validators, form }) {
  const [flipped, setFlipped] = useState(false);

  return (
    <>
      <div className="m-b:3">
        <Form.Item
          noStyle
          dependencies={["cardNumber,holderName,securityCode,expiration"]}
        >
          {() => (
            <PaymentCard
              number={form.getFieldValue("cardNumber")}
              holderName={form.getFieldValue("holderName")}
              cvv={form.getFieldValue("securityCode")}
              expiration={form.getFieldValue("expiration")}
              flipped={flipped}
            />
          )}
        </Form.Item>
      </div>
      <div className="m-t:2">
        <CardFields
          onFocus={setFlipped}
          validators={validators}
          focusBlurSecurity={setFlipped}
        />
      </div>
    </>
  );
}
