import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  message,
  Row,
} from "antd";

import PrimaryLayout from "../components/PrimaryLayout";
import { currencyFormatter } from "../utils/locale";
import useSWR, { mutate } from "swr";
import api, { get } from "../api/api";
import PaymentResult from "../components/PaymentResult";

export default function ChangeQuota() {
  const [loading, setLoading] = useState();
  const [value, setValue] = useState(0);
  const [billingInfo, setBillingInfo] = useState();
  const [response, setResponse] = useState();
  const { data: planInfo } = useSWR("/plans/plan_info/", get);

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      console.log(value);
      const { data: response } = await api.post("/plans/change_quota/", {
        num_items: value,
        quota_type: "USER",
      });
      setResponse(response);
      message.success("Alterações salvas com sucesso!");
      mutate("/plans/plan_info/");
      setLoading("SUCCESS_DB");
    } catch (e) {
      message.error(
        "Houve um erro com sua requisição, tente novamente mais tarde."
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (planInfo && planInfo.plan.has_quota_expansion && planInfo.quotas?.[0])
      setValue(planInfo.quotas[0].additional_quota);
  }, [planInfo]);

  useEffect(() => {
    api
      .post("/plans/info_change_quota/", {
        num_items: value || 0,
        quota_type: "USER",
      })
      .then(({ data }) => setBillingInfo(data));
  }, [value]);

  const userQuota = planInfo?.quotas?.[0] || {};
  const baseUsers = userQuota.num_items - userQuota.additional_quota;

  return (
    <PrimaryLayout title="Incluir/remover posições">
      <Row justify="center">
        <Col span={24} xl={8}>
          {response ? (
            <PaymentResult data={response} />
          ) : (
            <div className="m-y:3">
              <Card>
                <div className="t-a:c">
                  <h3>Alterar quantidade de posições adicionais</h3>
                </div>
                <div className="m-y:6">
                  <Form.Item label="Inclusos no plano">{baseUsers}</Form.Item>
                  <div className="t-a:c">
                    <Form.Item
                      label="Quantidade adicional de usuários"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <InputNumber
                        value={value}
                        onChange={setValue}
                        min={0}
                        size="large"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label="Novo total de usuários">
                    <b>{baseUsers + value}</b>
                  </Form.Item>
                  <Divider />
                  <div>
                    <Form.Item label="Cobrança adicional neste mês">
                      {currencyFormatter(billingInfo?.price) || "-"}
                    </Form.Item>
                    <Form.Item label="Créditos para os próximos pagamentos">
                      {currencyFormatter(billingInfo?.credits) || "-"}
                    </Form.Item>
                    <Form.Item label="Valor mensal recorrente">
                      <b>{currencyFormatter(billingInfo?.plan_price) || "-"}</b>
                    </Form.Item>
                  </div>
                </div>
                <p>
                  Ao clicar no botão de confirmação o valor será debitado
                  automaticamente do seu cartão de crédito cadastrado na
                  plataforma. Caso você tenha solicitado uma quantidade de
                  posições que seja inferior à atual, você receberá a diferença
                  de valor como crédito na próxima fatura. Você pode consultar
                  sua fatura em Pagamentos.
                </p>
                <div className="">
                  <Button
                    type="primary"
                    size="large"
                    // disabled={value === tionalMembers}
                    onClick={handleSubmit}
                    block
                    loading={loading}
                  >
                    Confirmar
                  </Button>
                </div>
              </Card>
            </div>
          )}
        </Col>
      </Row>
    </PrimaryLayout>
  );
}
