import React from "react";
import { Tag } from "antd";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  font-weight: 700;
`;

export default function PlanTag({ children, ...props }) {
  return (
    <StyledTag color="black" {...props}>
      {children}
    </StyledTag>
  );
}
