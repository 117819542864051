import { useCallback } from "react";
import useExternalScript from "./useExternalScript";

export default function useJunoCardManager() {
  const callback = useCallback(
    () =>
      new window.DirectCheckout(
        process.env.REACT_APP_JUNO_API_KEY,
        process.env.NODE_ENV === "production" &&
        process.env.REACT_APP_JUNO_SANDBOX !== "true"
          ? undefined
          : false
      ),
    []
  );
  const checkout = useExternalScript(callback);
  const generateHash = (data) =>
    new Promise((resolve, reject) =>
      checkout.getCardHash(data, resolve, reject)
    );

  const cardValidation = {
    cardNumber: (_, n) =>
      checkout.isValidCardNumber(n)
        ? Promise.resolve()
        : Promise.reject("Número inválido"),
    securityCode: ({ getFieldValue }) => ({
      validator: (_, s) =>
        checkout.isValidSecurityCode(getFieldValue("cardNumber"), s)
          ? Promise.resolve()
          : Promise.reject("Código de segurança inválido"),
    }),
    expirationDate: (__) => ({
      validator: (_, v) =>
        checkout.isValidExpireDate(v.split("/")[0], v.split("/")[1])
          ? Promise.resolve()
          : Promise.reject("Inválido"),
    }),
    getFlag: checkout?.getCardType,
  };

  const validateAndGenerateHash = async (cardData) => {
    if (!checkout.isValidCardData(cardData)) return Promise.reject();
    return await generateHash(cardData);
  };

  return { cardValidation, checkout, validateAndGenerateHash };
}
