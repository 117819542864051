import React from "react";
import MDI from "@mdi/react";
import styled from "styled-components";
import {
  mdiArrowUpBoldCircleOutline,
  mdiCheck,
  mdiPlus,
  mdiBarcodeScan,
  mdiCreditCardOutline,
  mdiEye,
  mdiCardAccountDetails,
} from "@mdi/js";

const icons = {
  upgrade: [0, mdiArrowUpBoldCircleOutline],
  check: [0, mdiCheck],
  plus: [0, mdiPlus],
  boleto: [0, mdiBarcodeScan],
  creditCard: [0, mdiCreditCardOutline],
  eye: [0, mdiEye],
  plan: [0, mdiCardAccountDetails],
};

const libraries = [
  (icon, props) => <MDI path={icon} {...props} />,
  (Icon, props) => <Icon {...props} />,
];

export default function Icon({ type, className = "", iProps = {}, ...props }) {
  return (
    <IconWrapper
      className={"lib-icon".concat(" ").concat(className)}
      {...props}
    >
      {libraries[icons[type][0]](icons[type][1], iProps)}
    </IconWrapper>
  );
}

const IconWrapper = styled.i`
  top: 0.125em;
  position: relative;
  svg {
    width: 1em;
    height: 1em;
  }
`;
