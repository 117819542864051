import React, { useEffect } from "react";
import { Button, Form, message } from "antd";

import AddressForm from "../AddressForm";
import api from "../../api/api";
import PayerInfoForm from "../PayerInfoForm";
import { mapErrors } from "../../utils/form";

export default function BillingAddress({ initialValues, mutate }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) form.resetFields();
  }, [form, initialValues]);

  const handleSubmit = async (values) => {
    try {
      await api.post(`/plans/billing_info/`, values);
      message.success("Alterações salvas com sucesso!");
      mutate();
    } catch (e) {
      const status = e?.response?.status;
      const data = e?.response?.data;
      if (status === 400) {
        message.error("Campos incorretos, corrija os erros!");
        mapErrors(form, data);
      }
      console.log(e);
    }
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <PayerInfoForm form={form} />
      {initialValues?.info_type === "FULL" && (
        <AddressForm form={form} hideBeforeCep={false} />
      )}
      <div className="t-a:r">
        <Button size="large" type="primary" htmlType="submit">
          Salvar
        </Button>
      </div>
    </Form>
  );
}
