import React, { Component } from "react";
import { captureException, showReportDialog } from "@sentry/browser";
import { withRouter } from "react-router";
import { Button, Result } from "antd";

const sentryOptions = {
  title: "Envie seu feedback sobre o erro",
  subtitle: "Nosso time já foi notificado sobre a falha. ",
  subtitle2:
    "Para que tenhamos um melhor entendimento, por favor descreva o que aconteceu.",
  labelComments: "O que aconteceu?",
  labelClose: "Fechar",
  labelSubmit: "Enviar",
  errrorGeneric:
    "Um erro desconhecido ocorreu ao enviar seu feedback. Por favor, tente novamente",
  errorFormEntry:
    "Algumas entradas estão erradas. Corriga os erros e tente novamente",
  successMessage: "Seu feedback froi enviado. Obrigado!",
};

class ErrorBoundary extends Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.log(error);

    const eventId = captureException(error);
    this.setState({ eventId });
    try {
      if (error.toString().indexOf("ChunkLoadError") > -1) {
        console.log("[ChunkLoadError] Reloading due to error");
        document.location.reload(true);
      }
    } catch {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname)
      this.setState({ hasError: false });
  }

  render() {
    const { children } = this.props;
    const { hasError, eventId } = this.state;

    if (hasError)
      return (
        <div className="flx-g:1 d:f a-i:c j-c:c t-a:c flx-d:c">
          <div className="t-a:c p-y:3">
            <img
              src={process.env.REACT_APP_MAIN_LOGO}
              alt="logo"
              style={{ maxHeight: 40 }}
            />
          </div>
          <Result
            status="500"
            title="Ops, parece que houve um erro!"
            subTitle="Nossa equipe já foi notificada do problema. Se desejar, você pode descrever um pouco melhor o que houve"
          />
          <div className="t-a:c">
            <Button
              size="large"
              onClick={() =>
                showReportDialog({
                  eventId,
                  lang: "pt",
                  ...sentryOptions,
                })
              }
            >
              Reportar problema
            </Button>
          </div>
        </div>
      );

    return children;
  }
}

export default withRouter(ErrorBoundary);
