import React, { useState } from "react";
import { Form, Input } from "antd";

import CepInput from "./Input/Cep";
import StateSelect from "./Input/State";
import { requiredRule } from "../utils/rules";

export default function AddressForm({ form, hideBeforeCep = true }) {
  const [cep, setCep] = useState();

  return (
    <>
      <Form.Item name="postcode" label="CEP" rules={[requiredRule]}>
        <CepInput
          form={form}
          onChange={() => !cep && setCep(true)}
          size="large"
        />
      </Form.Item>
      {(!hideBeforeCep || cep) && (
        <>
          <div className="d:f">
            <Form.Item
              label="Cidade"
              name="city"
              rules={[requiredRule]}
              className="flx-g:1"
              style={{ marginRight: 10 }}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label="UF"
              name="state"
              rules={[requiredRule]}
              className="w:auto"
            >
              <StateSelect placeholder="UF" size="large" />
            </Form.Item>
          </div>
          <Form.Item label="Bairro" name="avenue" rules={[requiredRule]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Rua/Logradouro"
            name="street"
            rules={[requiredRule]}
          >
            <Input size="large" />
          </Form.Item>
          <div className="d:f">
            <Form.Item
              label="Número"
              name="number"
              rules={[requiredRule]}
              style={{ width: "35%", marginRight: 10 }}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item label="Complemento" name="extra_data">
              <Input size="large" />
            </Form.Item>
          </div>
        </>
      )}
    </>
  );
}
