import React from "react";
import { Form, Input } from "antd";

import CpfInput from "./Input/Cpf";
import { requiredRule } from "../utils/rules";

export default function PayerInfoForm() {
  return (
    <>
      <Form.Item name="name" label="Nome do pagador" rules={[requiredRule]}>
        <Input size="large" placeholder="Insira seu nome" />
      </Form.Item>
      <Form.Item name="document" label="CPF ou CNPJ" rules={[requiredRule]}>
        <CpfInput size="large" placeholder="Insira seu documento" />
      </Form.Item>
      <Form.Item name="email" label="E-mail de contato" rules={[requiredRule]}>
        <Input size="large" placeholder="Insira seu e-mail" />
      </Form.Item>
    </>
  );
}
