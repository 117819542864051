import React from "react";
import { Form, Input } from "antd";
import MaskedInput from "antd-masked-input";

import { requiredRule } from "../utils/rules";

export default function CardFields({ validators, focusBlurSecurity }) {
  return (
    <>
      <Form.Item
        name="cardNumber"
        label="Número do cartão"
        hasFeedback
        rules={[requiredRule, { validator: validators.cardNumber }]}
      >
        <MaskedInput mask="1111 1111 1111 1111" size="large" />
      </Form.Item>
      <Form.Item
        name="holderName"
        label="Nome do titular do cartão"
        rules={[requiredRule]}
      >
        <Input size="large" placeholder="Conforme impresso no cartão" />
      </Form.Item>
      <Form.Item
        label="Válido até"
        rules={[requiredRule, validators.expirationDate]}
      >
        <Form.Item name="expiration" noStyle>
          <MaskedInput placeholder="__/____" mask="11/1111" size="large" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label="Código de segurança"
        name="securityCode"
        dependencies={["cardNumber", validators.securityCode]}
        hasFeedback
        rules={[requiredRule]}
      >
        <MaskedInput
          mask="111"
          onFocus={() => focusBlurSecurity(true)}
          onBlur={() => focusBlurSecurity(false)}
          size="large"
        />
      </Form.Item>
    </>
  );
}
