import { useState } from "react";
// import Helmet from "react-helmet";
import { Spin } from "antd";

import PaymentPage from "../views/Checkout";
import useJunoCardManager from "../hooks/useJunoCardManager";
import api from "../api/api";

export function mapCardData({
  holderName,
  cardNumber,
  securityCode,
  expiration,
  ...info
}) {
  return [
    info,
    (cardNumber || holderName || securityCode || expiration) && {
      cardNumber: cardNumber.replace(/\s/g, ""),
      holderName: holderName,
      securityCode: securityCode,
      expirationMonth: expiration.split("/")[0],
      expirationYear: expiration.split("/")[1],
    },
  ];
}

function Checkout({ location, history }) {
  const { checkout, cardValidation, validateAndGenerateHash } =
    useJunoCardManager();

  const [loading, setLoading] = useState(false);

  const handlePaymentInfo = async (info) => {
    if (loading) return;
    setLoading(true);

    const [newInfo, cardData] = mapCardData(info);
    try {
      let credit_card_hash;
      if (cardData) credit_card_hash = await validateAndGenerateHash(cardData);
      const data = { credit_card_hash, ...newInfo };
      await api.post("/plans/billing_info/", data);
      history.push(location.state.redirectTo || "/assinatura/resumo");
    } catch (e) {
      const message = e?.response?.data?.details[0]?.message;
      const code = e?.response?.data?.details[0]?.errorCode;
      if (message && code) throw e;
      setLoading(false);
      throw e;
    }
  };

  return (
    <main>
      {checkout ? (
        <PaymentPage
          loading={loading}
          onSubmitPaymentInfo={handlePaymentInfo}
          cardValidation={cardValidation}
          successMessage={
            <>
              Seu pagamento foi recebido e o agendamento foi realizado com
              sucesso. Enviamos as informações da consulta para o seu e-mail.
            </>
          }
        />
      ) : (
        <div className="m-y:5 t-a:c">
          <Spin />
        </div>
      )}
    </main>
  );
}

export default Checkout;
