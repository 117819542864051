import React from "react";
import { Card, Button, Result, Space } from "antd";
import Icon from "./Icon";
import { Link } from "react-router-dom";

export default function PaymentResult({ data }) {
  return (
    <Card>
      {data?.charge_type === "MANUAL" ? (
        <div className="t-a:c">
          <Result
            status="success"
            title="Boleto gerado"
            subTitle="Assim que o pagamento for efetuado e processado seu plano será alterado"
            className="p-b:3"
          />
          <Space className="w:full" direction="vertical">
            <Button
              block
              icon={<Icon type="eye" className="m-r:.7" />}
              type="primary"
              href={data.checkout_link}
              target="_blank"
              size="large"
              style={{ lineHeight: 1, paddingTop: 8 }}
            >
              Visualizar boleto
            </Button>
            <Link to="/assinatura">
              <Button size="large" block>
                Voltar à página de assinatura
              </Button>
            </Link>
          </Space>
        </div>
      ) : (
        <div className="t-a:c p-y:4">
          <Result
            status="success"
            title="Troca efetuada"
            subTitle="Seu pagamento está sendo processado e logo sua alteração será efetivada"
            className="p-b:3"
          />
          <div>
            <Link to="/assinatura">
              <Button size="large" block>
                Voltar à página de assinatura
              </Button>
            </Link>
          </div>
        </div>
      )}
    </Card>
  );
}
